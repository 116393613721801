.container-filter {
    display: flex;
    align-content: center;
    justify-content: center;
}

.icon-filter {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    border-radius: 20px;
    letter-spacing: 2px;
    padding: 10px 30px;
    background-color: var(--color-primary);
    color: #f2f2f2;
	cursor: pointer;
    height: 50px;
}


.icon-filter:hover {
    background-color: var(--color-primary-hover);
    border-radius: 20px;
    color: black;
}

.icon-filter .icon {
    width: 25px;
    height: 25px;
    stroke: #f2f2f2;
}

.icon-filter:hover .icon {
    stroke: black;
}