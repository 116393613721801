.card-body {
    padding: 5px;
    text-decoration: none;
}

.card-pokemon {
    cursor: pointer;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    background-color: #2c2c2c;
    transition: transform 0.3s;
}

.card-pokemon:hover {
    transform: scale(1.05);
}

.card-img {
    background-color: #f2f2f2;
    border-radius: 0px;
}

.card-img img {
    width: 100%;
}

.card-info {
    padding: 10px;
    color: #f2f2f2;
}

.pokemon-name {
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: right;
    font-size: large;
}

.pokemon-id {
    margin: 0;
    text-align: right;
    color: #f2f2f2;
    font-size:medium
}

.card-types {
    display: flex;
    justify-content: space-between;
}

.card-types span {
    flex: 1 0 45%;
    font-size: small;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    color: #f2f2f2;
    margin: 0 2.5%;
}

/* Types of Pokemon */
.water {
    background-color: var(--color-water);
}

.grass {
    background-color: var(--color-grass);
}

.poison {
    background-color: var(--color-poison);
}

.fire {
    background-color: var(--color-fire);
}

.bug {
    background-color: var(--color-bug);
}

.dragon {
    background: var(--color-dragon);
}

.flying {
    background-color: var(--color-flying);
}

.ground {
    background-color: var(--color-ground);
}

.steel {
    background-color: var(--color-steel);
}

.psychic {
    background-color: var(--color-psychic);
}

.ice {
    background-color: var(--color-ice);
}

.ghost {
    background-color: var(--color-ghost);
}

.normal {
    background-color: var(--color-normal);
}

.rock {
    background-color: var(--color-rock);
}

.electric {
    background-color: var(--color-electric);
}

.fighting {
    background-color: var(--color-fighting);
}

.fairy {
    background-color: var(--color-fairy);
}

.dark {
    background-color: var(--color-dark);
}