.container.pokemon-bottom {
    max-width: 800px;
    margin-bottom: 50px;
    width: 95%;
}

.stats-title {
    font-size: x-large;
    margin-left: 15px;
}

.pokemon-bottom {
    display: flex;
    gap: 50px;
    align-items: center;
    border: 1px solid #000;
    border-radius: 10px;
    background-color: lightgrey;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* optional: adds a subtle shadow */
}

.pokemon-stats {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
}

.stat-group {
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: 20% auto 8%;
}

.stat-group span {
    font-weight: 500;
    font-size: 18px;
}

.progress-bar {
    height: 30px;
    background-color: whitesmoke; /* color for the unfilled part */
    border-radius: 10px;
    position: relative;
}

.progress-bar-filled {
    height: 100%;
    background-color: var(--color-primary); /* color for the filled part */
    border-radius: 10px;
}

/*FOR MOBILE SCREENS*/
@media (max-width: 767px) {
    .container.pokemon-bottom {
        padding: 20px 0px;
    }
    .pokemon-bottom {
        flex-direction: column;
    }
    .stats-title {
        margin-left: 0;
        margin-bottom: -30px;
    }
    .pokemon-stats {
        width: 90%;
    }
    .stat-group {
        grid-template-columns: 20% auto 5%;
    }
    .counter-stat {
        display: flex;
        justify-content: flex-end;
    }
}