.container-button-load-more {
	display: flex;
	justify-content: center;
	padding: 20px;
}

.button-load-more {
	border: none;
	border-radius: 20px;
    padding: 10px 30px;
	letter-spacing: 2px;
	font-family: inherit;
	background-color: var(--color-primary);
	color: #f2f2f2;
	cursor: pointer;
	height: 50px;
}

.button-load-more:hover {
	background-color: var(--color-primary-hover);
	color: #000000;
}