.page-footer {
    background-color: #2c2c2c;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
}

.footer-logo {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.logo-img {
    width: 100px;
}

.logo-img:hover {
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.logo-text {
    display: flex;
    align-items: center;
    font-size: medium;
    color: #f2f2f2;
    margin-left: 20px;
    margin-bottom: 0px;
}