/*GENERAL STYLE*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

:root {
    --color-fire: #ff7402;
    --color-grass: #9bcc50;
    --color-steel: #9eb7b8;
    --color-water: #4592c4;
    --color-psychic: #f366b9;
    --color-ground: #ab9842;
    --color-ice: #51c4e7;
    --color-flying: #3dc7ef;
    --color-ghost: #4d5b64;
    --color-normal: #a4acaf;
    --color-poison: #7e0058;
    --color-rock: #a38c21;
    --color-fighting: #d56723;
    --color-dark: #707070;
    --color-bug: #729f3f;
    --color-dragon: linear-gradient(180deg, #53a4cf 50%, #f16e57 50%);
    --color-electric: #bba909;
    --color-fairy: #fdb9e9;
    --color-shadow: #7b62a3;
    --color-unknow: #757575;

    --color-primary: #006d77;
    --color-primary-hover: #83c5be;
}

body {
    font-family: 'Montserrat';
    background-color: #97a6b0;
}

.container{
    max-width: 1200px;
    margin: 0 auto;
}