.container.pokemon-top {
    max-width: 800px;
    width: 95%;
}

.divider {
    border-top: 3px solid var(--color-primary);
    margin: 0 auto;
}

.buttons {
    display: flex;
    justify-content: center;
}

.previous-button, .next-button {
    border: none;
    padding: 10px 30px;
	letter-spacing: 2px;
	font-family: inherit;
	background-color: var(--color-primary);
	color: #f2f2f2;
    height: 50px;
    width: 140px;
}

.previous-button {
    border-radius: 20px 0 0 0;
}

.next-button {
    border-radius: 0 20px 0 0;
}

.previous-button:hover, .next-button:hover {
	background-color: var(--color-primary-hover);
	color: black;
}

.previous-button:disabled, .next-button:disabled {
	background-color: var(--color-primary-hover);
	color: black;
    cursor: not-allowed;
}

.pokemon-number {
    font-size: clamp(24px, 15vw, 150px); /* New line: font size will be at least 24px and at most 150px */
    font-weight: 700;
    color: var(--color-primary);
    opacity: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.pokemon-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.pokemon-img img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.small-card{
    border: 1px solid #000;
    border-radius: 10px;
    background-color: lightgrey;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* optional: adds a subtle shadow */
}

.pokemon-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 50px;
    padding: 0;
}

.pokemon-page-name {
    text-align: right;
    font-size: 40px;
}

.pokemon-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
}

.group-info p {
    font-size: large;
    font-weight: 700;
}

.group-info span {
    display: flex;
    justify-content: center;
    font-size: large;
}

/*FOR MOBILE SCREENS*/
@media (max-width: 767px) {
    .pokemon-img,
    .pokemon-description {
        display: flex;
        justify-content: space-evenly;
    }

    .pokemon-page-name {
        text-align: right;
        margin-right: 9px;
        font-size: 40px;
    }
}